dhtmlxApp = function(e) {
    function t() {
        for (var e = this._craft_handlers, t = e.length - 1; t >= 0; t--) console.log("detach " + e[t]), a.detachEvent(e[t]);
        this._craft_handlers = []
    }
    if (this === window) return new dhtmlxApp(e);
    var a = dhtmlxApp.activeApp = this;
    return a.config = e, a.locale = function(e) {
        return a.locale.current[e] || e
    }, a.start = function(e, t) {
        if (a.templates = a.templates || dhtmlxApp.templates.app, t)
            for (var n in t) this.config[n] = t[n];
        a.locale.current = a.locale[this.config.$lang || "en"], a.renderView(a.config.$start, e), a.container = e, a.callEvent("onAppReady", [])
    }, a.defineView = function(e, t, n) {
        a.attachEvent("render:" + e, function(o) {
            if (o && (o._craft_id !== e || n)) {
                var l = a._craft_cell;
                a._craft_cell = o, t.apply(a, arguments), a._craft_cell = l, o._craft_id = e
            }
        })
    }, a.renderView = function(e, n) {
        (n.tagName || "string" == typeof n) && (n = new dhtmlxApp.HTMLWrapper(n)), n && n.attachEvent && !n._craft_handlers && (n.attachEvent("_onCellUnload", t), n.attachEvent("_onBeforeContentDetach", t), n._craft_handlers = []);
        var o = [].concat.apply([], arguments).slice(1);
        a.callEvent("render:" + e, o)
    }, a.dhxevs = {
        data: {}
    }, a.attachEvent = function(e, t) {
        e = String(e).toLowerCase(), this.dhxevs.data[e] || (this.dhxevs.data[e] = []);
        var a = window.dhx4.newId();
        this.dhxevs.data[e].push([a, t]);
        var n = e + "@@@" + a;
        return this._craft_cell && !this._craft_cell.wrapper && (this._craft_cell._craft_handlers || (this._craft_cell._craft_handlers = []), this._craft_cell._craft_handlers.push(n)), n
    }, a.detachEvent = function(e) {
        var t = e.split("@@@"),
            a = this.dhxevs.data[t[0]];
        if (a)
            for (var n = 0; n < a.length; n++)
                if (a[n][0] == t[1]) return a.splice(n, 1)
    }, a.callEvent = function(e, t) {
        e = String(e).toLowerCase();
        var a = this.dhxevs.data[e];
        if (null == a) return !0;
        for (var n = !0, o = 0; o < a.length; o++) n = a[o][1].apply(this, t) && n;
        return n
    }, a.unload = function() {
        a.callEvent("onAppUnload", []), a.container && (a.container.attachHTMLString ? a.container.attachHTMLString("") : a.container.innerHTML = "");
        for (var e in a) a[e] = null;
        dhtmlxApp.activeApp = a = null
    }, this
}, dhtmlxApp.start = function(e, t, a) {
    var n = this.apps[e];
    n();
    var o = dhtmlxApp.activeApp;
    return o.templates = dhtmlxApp.templates[e], o.start(t, a), o
}, dhtmlxApp.template = function(e, t, a) {
    var n = dhtmlxApp.templates[e] = dhtmlxApp.templates[e] || {};
    n[t] = a
}, dhtmlxApp.apps = {}, dhtmlxApp.templates = {}, dhtmlxApp.HTMLWrapper = function(e) {
    this._node = e
}, dhtmlxApp.HTMLWrapper.prototype = {
    _isCell: !0,
    unload: function() {
        this._node = null
    },
    attachLayout: function(e) {
        return e.parent = this._node, new dhtmlXLayoutObject(e)
    },
    wrapper: !0
}, dhx4.ajax.query = function(e) {
    var t;
    return e.promise && dhx4.promise && (t = dhx4.promise.defer(), e.callback = function(e) {
        t.resolve(JSON.parse(e.xmlDoc.responseText))
    }), dhx4.ajax._call(e.method || "GET", e.url, e.data || "", e.async || !0, e.callback, null, e.headers), t
}, dhtmlxApp.apps.formbuilder = function() {
    var app = dhtmlxApp({
        $start: "Layout"
    });
    app.defineView("Layout", function(top) {
        function reenable() {
            var e = treeHistory.length - 1;
            // myToolbar[historyIndex > 0 ? "enableItem" : "disableItem"]("undo"), myToolbar[historyIndex < e ? "enableItem" : "disableItem"]("redo")
        }
        var myLayout = new dhtmlXLayoutObject(top, "2U");
        myLayout.cells("a").setText("Tree Struct");

        var winExport, winImport, exportedForm = "";
         app.attachEvent("onPreviewDataProvide", function(e, t) {
            if ("export" == t && null != winExport) {
                var a = winExport.getAttachedObject();
                a.setItemValue("data", app.objToStr(e.data)), exportedForm = JSON.stringify(e), a = null
            }
        });
        var myTabbar = window.myTabbar = myLayout.cells("b").attachTabbar({
            tabs: [{
                id: "mockup",
                text: "Mockup"
            }, {
                id: "preview",
                text: "Preview"
            }]
        });
        app.topCell = myLayout;
        myTabbar.attachEvent("onSelect", function(e) {
            var t = this.cells(e),
                a = {
                    mockup: "Mockup",
                    preview: "Preview"
                }[e];
            return window.setTimeout(function() {
                app.renderView(a, [t]), t = a = null
            }, 1), !0
        }), myTabbar.goToNextTab(), myTabbar.attachEvent("onSelect", function(e) {
            return app.callEvent("onTabbarSelect", [e]), !0
        }), app.renderView("Tree", [myLayout.cells("a")]), app.renderView("Property", []), app.attachEvent("onAppUnload", function() {
            null != window.dhxWins && (dhxWins.unload(), dhxWins = null)
        });
        var treeHistory = [],
            historyIndex = -1;
        app.attachEvent("onUndo", function() {
            historyIndex < 1 || (--historyIndex, app.callEvent("onDataImport", [treeHistory[historyIndex].data, {
                fromHistory: !0
            }]), reenable())
        }), app.attachEvent("onRedo", function() {
            historyIndex >= treeHistory.length - 1 || (++historyIndex, app.callEvent("onDataImport", [treeHistory[historyIndex].data, {
                fromHistory: !0
            }]), reenable())
        }), app.attachEvent("onHistoryRecordProvide", function(e) {
            treeHistory = treeHistory.slice(0, ++historyIndex), treeHistory[historyIndex] = e, reenable()
        })
    }), app.defineView("Mockup", function(e) {
        function t() {
            if (!n) {
                var e = o.exportToForm(null, {
                    fakeNames: !0
                });
                app.callEvent("onPreviewDataProvide", [e, "preview"])
            }
        }

        function a() {
            n || app.callEvent("onHistoryRecordProvide", [o.exportToForm()])
        }
        var n = !1,
            o = window.myMockup = e.attachMockup();
        o.attachEvent("onSelect", function(e, t) {
            app.callEvent("onItemSelect", ["mockup", e, t])
        }), o.attachEvent("onAdd", function(e, n, l) {
            app.callEvent("onTreeUpdateProvide", [o.exportToTree()]), o.clearSelection(), o.selectItem(n, !0, !0), t(), a()
        }), o.attachEvent("onNodeMove", function(e, n) {
            app.callEvent("onTreeUpdateProvide", [o.exportToTree()]), o.clearSelection(), o.selectItem(n, !0, !0), t(), a()
        }), o.attachEvent("onDelete", function(e, t) {
            app.callEvent("onItemDelete", ["mockup", e, t])
        }), o.attachEvent("onNodeMove", function(e, n) {
            t(), a()
        });

        // AKIOMA UPDATE
        // remove default load
        var l = [
        ];

        app.attachEvent("onItemSelect", function(e, t, a) {
            "mockup" != e && o.selectItem(t, a, !1)
        }), app.attachEvent("onItemDelete", function(e, n, l, silent) {

            if("mockup" != e){
                o.deleteItem(n, !1, !1);
                t();
                a();
            }
            
        }), app.attachEvent("onConfigRequest", function(e) {
            // Akioma update this is where we need to update attributes from repository
            var t = o.getItemData(e);
            if(app.config.onFormFieldSelect){
                
                var promiseGetFormFields = app.config.onFormFieldSelect(t);
                if(promiseGetFormFields){
                    promiseGetFormFields.done(function(res){

                        try{
                            var cFieldType = t[Object.keys(t)[0]].type;
                            window.myPropView.clearAll();
                            var copyobj = Object.assign({},window.formBuilder.config.propGroups.Common);
                            window.formBuilder.config.propGroups.Common = {
                                name: copyobj.name,
                                type: copyobj.type
                            };
                            
                            for(var x in window.formBuilder.config.itemProps){
                                window.formBuilder.config.itemProps[x] = [];
                            }

                            window.formBuilder.config.itemProps['input'] = ['name', 'type'];
                            // end test
                            // AKIOMA custom form
                            var oCurrProp = t[Object.keys(t)[0]];
                            for(var j in res){
                                var oRepoAttr = res[j];
                                var cLabel = oRepoAttr.label;
                                if(cLabel != 'type'){
                                   t[Object.keys(t)[0]][cLabel] = oRepoAttr.value;
                                   if(window.formBuilder.config.itemProps[cFieldType].indexOf(cLabel) == -1){
                                    window.formBuilder.config.itemProps[cFieldType].push(cLabel);
                                    window.formBuilder.config.propGroups.Common[cLabel] = { 
                                        name: cLabel, 
                                        type: oRepoAttr.type,
                                        readonly: oRepoAttr.original.readonly,
                                        original: oRepoAttr.original,
                                        v: ((oRepoAttr.type != 'checkbox') ? "str" : "bool") //maybe bool for checkbox??
                                    };
                                   }
                                     
                                }
                                
                            }


                            // load new attributes here
                            app.callEvent("onConfigProvide", [t])
                        }catch(e){
                            console.warn(e)
                        }
                        
                    });
                }
                    
            }
        }), app.attachEvent("onConfigUpdate", function(e, n, l, i) {
            for (var p in e) o.setItemData(p, n, l);
            var r = o.getItemData(app.keys(e));
            app.callEvent("onConfigProvide", [r]), "preview" != i && t(), a()
        }), app.attachEvent("onPreviewDataRequest", function(e) {
            var t = {};
            "preview" == e && (t.fakeNames = !0), "export" == e && (t.hideIds = !0);
            var a = o.exportToForm(null, t);
            app.callEvent("onPreviewDataProvide", [a, e])
        }), app.attachEvent("onDataImport", function(e, l) {
            n = !0, l = l || {}, o.loadStruct(e, function() {
                app.callEvent("onTreeUpdateProvide", [o.exportToTree()]), n = !1, t(), l.fromHistory || a()
            })
        }), app.attachEvent("onTreeUpdateRequest", function() {
            app.callEvent("onTreeUpdateProvide", [o.exportToTree()])
        }), app.attachEvent("onItemCopyRequest", function(e, t) {
            var a = {
                item: "copyItem",
                column: "copyColumn",
                block: "copyBlock"
            }[e];
            a && o[a](t, null, {
                callEvent: !0
            })
        }), app.attachEvent("onItemAddRequest", function(e, t) {
            "item" === e ? o.addItem(t, {
                type: "input",
                label: "New Input",
                value: ""
            }, {
                callEvent: !0
            }) : "block" === e ? o.addBlock(t, {
                type: "block"
            }, {
                callEvent: !0
            }) : "column" === e && o.addColumn(t, {
                type: "column"
            }, {
                callEvent: !0
            })
        }), app.attachEvent("onItemMove", function(e, t, a, n) {
            // check for custom mockup onItemMove handler and apply

            n = n || {}, n.callEvent = !0, o.moveNode(t, a, n);

            if(o.config.onElementMove){
                o.config.onElementMove(t, a, n, o.items, null, o.dndMap.item);
            }
        })
    }), app.defineView("Preview", function(e) {
        var t = window.myForm = e.attachForm(),
            a = !0;
        t.fixRadioId = function(e) {
            if (null == t.getItemType(e))
                for (var a in dhtmlXForm.prototype.items.radio.r)
                    if (t.getUserData(a, "_idd") == e) {
                        e = a;
                        break
                    }
            return e
        }, t.getRadioItems = function(e) {
            var a = dhtmlXForm.prototype.items.radio.r,
                n = [];
            for (var o in a) {
                var l = t.getUserData(o, "_idd");
                l && n.push({
                    id: l,
                    value: a[o]._value,
                    checked: a[o]._checked
                })
            }
            return n
        }, t.attachEvent("onChange", function(e, a, n) {
            var o = t.getItemType(e),
                l = {};
            if ("radio" == o) {
                var i = t.getRadioItems(e);
                i.forEach(function(e) {
                    l = {}, l[e.id] = 1, app.callEvent("onConfigUpdate", [l, "checked", e.checked, "preview"])
                })
            } else "checkbox" == o ? (l[e] = 1, app.callEvent("onConfigUpdate", [l, "checked", n, "preview"])) : (l[e] = 1, app.callEvent("onConfigUpdate", [l, "value", a, "preview"]))
        }), app.attachEvent("onPreviewDataProvide", function(e, n) {
            try{
                if ("preview" == n && a) {
                    var o = t.cont,
                        l = o.scrollTop,
                        i = o.scrollLeft;
                    "function" == typeof t._emuSelCacheClear && t._emuSelCacheClear();
                    for (var p in t.objPull) t._removeItem(String(p).replace(t.idPrefix, ""));

                    $(t.base).remove();
                    t.loadStruct(e.data, function() {
                        for (var a in e.selected) t.emulateSelect(t.fixRadioId(a), !0);
                        o.scrollLeft = i, o.scrollTop = l, o = null
                    })
                }
            }catch(e){
                console.warn(e);
            }
            
        }), app.callEvent("onPreviewDataRequest", ["preview"]), app.attachEvent("onTabbarSelect", function(e) {
            return "preview" == e ? (a = !0, app.callEvent("onPreviewDataRequest", ["preview"])) : a = !1, !0
        }), app.attachEvent("onItemSelect", function(e, n, o) {
            if (1 == a) {
                n instanceof Array || (n = [n]);
                for (var l = 0; l < n.length; l++) t.emulateSelect(t.fixRadioId(n[l]), o)
            }
        }), app.attachEvent("onAppUnload", function() {
            t._emuSelCacheClear()
        })
    }), app.defineView("Property", function(e) {
        function t() {
            null != o && window.clearTimeout(o), o = window.setTimeout(a, l)
        }

        function a() {
            o = null;
            var e = [],
                t = [],
                a = n.cont,
                l = a.scrollTop,
                c = a.scrollLeft;
            for (var d in p) (r[d]) && t.indexOf(r[d].type) < 0 && (t.push(r[d].type), e.push(app.config.itemProps[r[d].type]));
            var s = t.sort().join("_");
            if (n.clearAll(), e.length > 0) {
                i[s] = null;
                if (null == i[s]) {
                    e = 1 == e.length ? e[0] : app.getIntersect.apply(app, e);
                    var u = [];
                    for (var f in app.config.propGroups) {
                        var t = [{
                            type: "group",
                            label: f
                        }];
                        if(e)
                            for (var m in app.config.propGroups[f]) e.indexOf(m) >= 0 && t.push(app.config.propGroups[f][m]);
                        t.length > 1 && (u = u.concat(t))
                    }
                    i[s] = window.dhx4._copyObj(u)
                }
                n.loadStruct(window.dhx4._copyObj(i[s]), function() {
                    var e = [];
                    for (var t in p) e.push(r[t]);
                    e = app.getIntersect.apply(app, e), n.setValues(e), a.scrollLeft = c, a.scrollTop = l, a = null
                })
            }
        }
        var n = window.myPropView = e.attachPropertyViewForm();
        n.attachEvent("onChange", function(e, t) {
            for (var n in p) "" == t || null == t ? "undefined" != typeof r[n][e] && delete r[n][e] : r[n][e] = t;
            
            // AKIOMA UPDATE 
            // update attribute values here
            if(app.config.onConfigUpdate){
                app.config.onConfigUpdate(Object.keys(p)[0],e,t);
            }else{
                app.callEvent("onConfigUpdate", [window.dhx4._copyObj(p), e, t]), "type" == e && a()
            }
        });
        var o = null,
            l = 1,
            i = {},
            p = {},
            r = {};
        app.attachEvent("onItemSelect", function(e, a, n) {
            a instanceof Array || (a = [a]);
            for (var o = 0; o < a.length; o++) 1 == n ? p[a[o]] = !0 : delete p[a[o]];
            var l = [];
            for (var i in p) null == r[i] && l.push(i);
            app.callEvent("onConfigRequest", [a])
        }), app.attachEvent("onConfigProvide", function(e) {
            for (var a in e) r[a] = e[a];
            t()
        }), app.attachEvent("onItemDelete", function(e, a, n) {
            null != r[a] && delete r[a], 1 == p[a] && (delete p[a], t())
        })
    }), app.defineView("Tree", function(e) {
        function t(e) {
            return !!{
                block: 1,
                column: 1,
                item: 1
            }[e.type]
        }


        // this weird function name is where the drag&drop Allow is set
        function a(e, t, a,d) {
            // if ("block" == t.type && 0 == a) return !1;
            var n = {
                root: {
                    column: 1,
                    block: 1,
                    item: 1
                },
                block: {
                    column: 1,
                    block: 1,
                    item: 1
                },
                column: {
                    item: 1,
                    block: 1,
                    column: 0
                }
            };
            // return true;
            try{
                console.log(t.type,e.type);
                console.log(t.type,e.type, n[t.type], n[t.type][e.type]);
            }catch(e){
                console.log(e);
            }
            
            // console.log(window.myMockup.items[d].node);
            // 
            var bRoot = (t.type == 'root');

            if(!bRoot){
                var iLengthChildrenCols = _.filter(window.myMockup.items, function(elem){
                    return (window.myMockup.items[d] && window.myMockup.items[d].baseId == elem.parentId && elem.formData && elem.formData.type == "column")
                }).length;
            }else{
                iLengthChildrenCols = _.filter(window.myMockup.items, function(elem){
                    return (window.myMockup.items[d] && window.myMockup.items[d].id == elem.parentId && elem.formData && elem.formData.type == "column")
                }).length;
            }
            

            // console.log(iLengthChildrenCols,t.type, e.type );
            // console.log(window.myMockup.items[d], window.myMockup.items)
            // 
            var bRootCol = (t.type == 'root' && e.type == 'column');

            var bBlockCol = (t.type == 'block' && e.type == 'column');
            if(!bRoot && iLengthChildrenCols > 0 && !bBlockCol){
                console.log('stop!');
                return false;
            }else if(bRoot && iLengthChildrenCols > 0 && !bRootCol){
                return false;
            }

            return n[t.type] && n[t.type][e.type]
        }

        function n(e) {
            if (!e) return {
                type: "block"
            };
            var t = {
                    type: null
                },
                a = l.getUserData(e, "type") || "";
            if(a == 'root')
                return { type : 'root' };

            return app.treeContextMenuItemTypes[a] ? t.type = "item" : a.match(/column$/gi) ? t.type = "column" : a.match(/(fieldset|block)$/gi) && (t.type = "block"), t
        }
        var o = window.myTreeMenu = new dhtmlXMenuObject({
            context: !0,
            items: [{
                id: "column_delete",
                text: "Delete Column"
            }, {
                id: "column_add_block",
                text: "Add Block"
            }, {
                id: "block_delete",
                text: "Delete Block"
            }, {
                id: "block_add_column",
                text: "Add Column"
            }, {
                id: "item_delete",
                text: "Delete Item"
            }, {
                id: "all_open_master",
                text: "Open Object Master"
            }]
        });
        var aNotSupported = ["column_add_block","block_add_column"];
        o.filterItemsByText = function(e) {
            var t = this,
                a = new RegExp("^" + e),
                val = e;
            this.forEachItem(function(e) {
                t[(null != e.match(a) || (e.startsWith('all') && val !== "root")) ? "showItem" : "hideItem"](e)
            }), a = t = null
        }, o.attachEvent("onClick", function(e) {
            if(aNotSupported.indexOf(e) != -1){
                uiMessage.showInfo({ text: 'This feature is currently not supported. Please use the Mockup buttons to add columns or blocks.', expire: 3000 });
                return false;
            }
            var t;
            if(e != "block_delete"){
                null != e.match(/delete$/gi) && app.callEvent("onItemDelete", ["ctx", this.conf.ctx_id, e.replace(/_delete$/, "")]), t = /(column|block|item)_copy$/gi.exec(e), null != t && app.callEvent("onItemCopyRequest", [t[1], this.conf.ctx_id]), t = /add_(column|block|item)$/gi.exec(e), null != t && app.callEvent("onItemAddRequest", [t[1], this.conf.ctx_id])
            }else{
                return true;
            }
        });
        var l = window.myTreeView = e.attachTreeView({
            dnd: !0,
            multiselect: !0,
            context_menu: !0
        });
        l.attachEvent("onBeforeDrag", function(e) {
            // return true;
            return t(n(e))
        }), l.attachEvent("onDragOver", function(e, t, o) {
            console.log(e,t,o);
            //e - dragged
            // t - destination
            // filter for children column to stop drop zone if there is already a column
            // var iLengthChildrenCols = _.filter(window.myMockup.items, function(elem){
            //     return (window.myMockup.items[t].parentId == elem.parentId && elem.formData && elem.formData.type == "column")
            // }).length;
            // if(iLengthChildrenCols > 0)
            //     return false;
            // console.log(n(e), n(t), o)

            // return true;
            return a(n(e), n(t), o, t)
        }), l.attachEvent("onDrop", function(e, t, a) {
            var o = n(e).type;
            ("column" == o || "base" == o  || "block" == o  || "fieldset" == o ) && --a, app.callEvent("onItemMove", [o, e, t, {
                childIndex: a
            }])
        }), l.attachEvent("onSelect", function(e, t) {
            app.callEvent("onItemSelect", ["tree", e, t])
        }), l.attachEvent("onContextMenu", function(e, t, a) {
            o.conf.ctx_id = e;
            var n = l.getUserData(e, "type");
            if ("column" == n) o.filterItemsByText("column");
            else if ("block" == n || "fieldset" == n) o.filterItemsByText("block");
            else {
                if (!app.treeContextMenuItemTypes[n]) return !1;
                o.filterItemsByText("item")
            }
            return l.selectItem(e), o.showContextMenu(t, a), !1
        });
        app.attachEvent("onTreeUpdateProvide", function(e) {
            l.clearAll(), l.loadStruct(e)
        }), app.attachEvent("onItemSelect", function(e, t, a) {
            "tree" != e && (1 == a ? l.selectItem(t, !1, !0) : l.unselectItem(t))
        }), app.attachEvent("onItemAdd", function(e, t, a, n) {
            "tree" != e && (console.warn("myTreeView.addItem syntax changed, recheck!"), l.addItem(a, t, n))
        }), app.attachEvent("onItemDelete", function(e, t, a, silent) {
            try{
            
                if("tree" != e && (null == l.getParentId(t))){
                     window.setTimeout(function() {
                        app.callEvent("onTreeUpdateRequest", [])
                    }, 1); 
                }else{
                    l.deleteItemFormBuilder(t, true)
                }
                
            }catch(e){
                console.warn(e);
            }
            
        }), app.attachEvent("onConfigProvide", function(e) {
            for (var t in e) {
                var a = e[t].type,
                    n = e[t].label;
                "button" == a && (n = e[t].value), {
                    column: 1,
                    fieldset: 1,
                    block: 1
                }[a] ? l.setItemText(t, "[" + t + "] [" + a + "] " + n) : app.treeContextMenuItemTypes[a] && l.setItemText(t, "[" + t + "] [" + a + "] " + n), e[t].icon && l.setItemIcons(t, {
                    file: e[t].icon
                })
            }
        }), app.attachEvent("onConfigUpdate", function(e, t, a) {
            if ({
                    type: 1,
                    label: 1,
                    value: 1
                }[t])
                for (var n in e) l.setUserData(n, t, a)
        })
    }), app.treeContextMenuItemTypes = {
        input: !0,
        password: !0,
        select: !0,
        multiselect: !0,
        combo: !0,
        checkbox: !0,
        radio: !0,
        label: !0,
        button: !0,
        hidden: !0,
        template: !0,
        container: !0,
        calendar: !0,
        colorpicker: !0,
        editor: !0,
        btn2state: !0,
        image: !0,
        uploader: !0,
        file: !0
    }, app.formatFormToTree = function(e) {
        for (var t = [], a = 0; a < e.length; a++) {
            var n = null;
            "settings" == e[a].type || ("column" == e[a].type ? n = {
                id: dhx4.newId(),
                text: "[column]"
            } : (n = {
                id: e[a].name,
                text: "[" + String(e[a].type).charAt(0) + "] " + (e[a].label || e[a].name)
            }, null != e[a].list && (n.text = "[block]", n.item = app.formatFormToTree(e[a].list), n.open = !0))), null != n && t.push(n)
        }
        return t
    }, app._cmpIntersect = function(e, t) {
        if (e instanceof Array)
            for (var a = [], n = 0; n < e.length; n++) t.indexOf(e[n]) >= 0 && a.push(e[n]);
        else {
            var a = {};
            for (var o in e) e[o] == t[o] && (a[o] = t[o])
        }
        return a
    }, app.getIntersect = function() {
        for (var e = [], t = 0; t < arguments.length; t++) e.push(window.dhx4._copyObj(arguments[t]));
        for (var t = 1; t < e.length; t++) e[0] = app._cmpIntersect(e[0], e[t]);
        return e[0]
    }, app.objToStr = function(e, t) {
        "undefined" == typeof t && (t = 0);
        var a = "";
        if (e instanceof Array) {
            for (var n = [], o = 0; o < e.length; o++) n.push(app.objToStr(e[o], t + 1));
            a += n.length > 0 ? "[\n" + n.join(",\n") + "\n" + "\t".repeat(t) + "]" : "[]"
        } else if ("object" == typeof e)
            if (null == e) a += "null";
            else {
                var n = [];
                for (var l in e) n.push(l + ": " + app.objToStr(e[l], t));
                a += n.length > 0 ? "\t".repeat(t) + "{" + n.join(", ") + "}" : "{}"
            }
        else "string" == typeof e ? a += '"' + e.replace(/([\"\'])/g, "\\$1") + '"' : "number" == typeof e ? a += e : "boolean" == typeof e && (a += e ? "true" : "false");
        return a
    }, app.saveFile = function(e, t, a) {
        a = a || "text/plain";
        var n = document.createElement("textarea"),
            o = document.createElement("input"),
            l = document.createElement("input"),
            i = document.createElement("iframe"),
            p = document.createElement("form");
        i.style = "display:none", o.name = "filename", o.value = t, n.name = "content", n.value = e, l.value = a, l.name = "type", p.action = "assets/download.php", p.method = "post", p.appendChild(n), p.appendChild(o), p.appendChild(l), i.appendChild(p), document.body.appendChild(i), p.submit(), i.remove()
    }, app.loadFile = function(e) {
        var t = "dhtmlx-formbuilder-import-data-file-prompt",
            a = document.getElementById(t);
        if (!a) {
            var a = document.createElement("input");
            a.id = t, a.type = "file", a.style = "visibility:hidden", a.onchange = function(t) {
                var n = (t.target || window.event.srcElement).files || [];
                if (FileReader && n.length) {
                    var o = new FileReader;
                    o.onload = function() {
                        e(null, JSON.parse(o.result))
                    }, o.onerror = function() {
                        e("Error while loading a file")
                    }, o.readAsText(n[0]), a.remove()
                } else e("Too old browser")
            }, document.body.appendChild(a)
        }
        a.click()
    }, app.keys = function(e) {
        var t = [];
        for (var a in e) e.hasOwnProperty(a) && t.push(a);
        return t
    }, app.config.itemProps = {
        select: ["name", "type", "position", "offsetLeft", "offsetTop", "label", "labelAlign", "labelWidth", "labelHeight", "labelLeft", "labelTop", "info", "tooltip", "inputWidth", "inputHeight", "inputLeft", "inputTop", "connector", "style", "className", "disabled", "hidden", "validate", "required", "noteText", "noteWidth", "userdataName", "userdataValue"],
        input: ["test","name", "type", "position", "offsetLeft", "offsetTop", "label", "labelAlign", "labelWidth", "labelHeight", "labelLeft", "labelTop", "info", "tooltip", "inputWidth", "inputHeight", "inputLeft", "inputTop", "rows", "maxLength", "numberFormat", "value", "style", "className", "readonly", "disabled", "hidden", "validate", "required", "noteText", "noteWidth", "userdataName", "userdataValue"],
        label: ["name", "type", "offsetLeft", "offsetTop", "label", "labelWidth", "labelHeight", "labelLeft", "labelTop", "className", "disabled", "hidden", "userdataName", "userdataValue"],
        button: ["name", "type", "offsetLeft", "offsetTop", "value", "width", "inputLeft", "inputTop", "tooltip", "className", "disabled", "hidden", "userdataName", "userdataValue"],
        checkbox: ["name", "type", "position", "offsetLeft", "offsetTop", "label", "labelAlign", "labelWidth", "labelHeight", "labelLeft", "labelTop", "info", "tooltip", "inputLeft", "inputTop", "value", "checked", "className", "readonly", "disabled", "hidden", "required", "noteText", "noteWidth", "userdataName", "userdataValue"],
        block: ["name", "blockType", "width", "blockOffset", "offsetLeft", "offsetTop"],
        btn2state: ["name", "type", "offsetLeft", "offsetTop", "position", "info", "label", "labelAlign", "labelWidth", "labelHeight", "labelLeft", "labelTop", "tooltip", "inputWidth", "inputHeight", "inputLeft", "inputTop", "value", "checked", "className", "cssName", "disabled", "hidden", "required", "readonly", "noteText", "noteWidth", "userdataName", "userdataValue"],
        calendar: ["name", "type", "position", "offsetLeft", "offsetTop", "info", "label", "labelAlign", "labelWidth", "labelHeight", "labelLeft", "labelTop", "tooltip", "inputWidth", "inputHeight", "inputLeft", "inputTop", "value", "dateFormat", "serverDateFormat", "calendarPosition", "minutesInterval", "weekStart", "enableTime", "showWeekNumbers", "className", "disabled", "hidden", "readonly", "required", "validate", "noteText", "noteWidth", "userdataName", "userdataValue"],
        colorpicker: ["name", "type", "position", "offsetLeft", "offsetTop", "info", "label", "labelAlign", "labelWidth", "labelHeight", "labelLeft", "labelTop", "tooltip", "inputWidth", "inputHeight", "inputLeft", "inputTop", "value", "cpPosition", "enableCustomColors", "customColors", "className", "disabled", "hidden", "readonly", "required", "validate", "noteText", "noteWidth", "userdataName", "userdataValue"],
        combo: ["name", "type", "position", "offsetLeft", "offsetTop", "info", "label", "labelAlign", "labelWidth", "labelHeight", "labelLeft", "labelTop", "tooltip", "inputWidth", "inputLeft", "inputTop", "value", "connector", "options", "comboType", "comboImagePath", "comboDefaultImage", "comboDefaultImageDis", "filtering", "serverFiltering", "filterCache", "filterSubLoad", "className", "disabled", "hidden", "readonly", "required", "validate", "style", "noteText", "noteWidth", "userdataName", "userdataValue"],
        container: ["name", "type", "position", "offsetLeft", "offsetTop", "info", "label", "labelLeft", "labelTop", "tooltip", "inputWidth", "inputLeft", "inputTop", "inputHeight", "className", "hidden", "required", "style", "noteText", "noteWidth", "userdataName", "userdataValue"],
        editor: ["name", "type", "position", "offsetLeft", "offsetTop", "info", "label", "labelAlign", "labelWidth", "labelHeight", "labelLeft", "labelTop", "tooltip", "inputWidth", "inputLeft", "inputTop", "inputHeight", "value", "className", "hidden", "disabled", "required", "validate", "noteText", "noteWidth", "userdataName", "userdataValue"],
        fieldset: ["name", "position", "blockType", "offsetLeft", "offsetTop", "label", "inputLeft", "inputTop", "fieldsetWidth", "className", "hidden", "disabled", "userdataName", "userdataValue"],
        file: ["name", "type", "position", "offsetLeft", "offsetTop", "info", "label", "labelAlign", "labelWidth", "labelHeight", "labelLeft", "labelTop", "tooltip", "inputLeft", "inputTop", "className", "hidden", "disabled", "required", "noteText", "noteWidth", "userdataName", "userdataValue"],
        hidden: ["name", "type", "value", "userdataName", "userdataValue"],
        image: ["name", "type", "position", "offsetLeft", "offsetTop", "info", "label", "labelAlign", "labelWidth", "labelHeight", "labelLeft", "labelTop", "tooltip", "inputWidth", "inputHeight", "inputLeft", "inputTop", "value", "imageUrl", "imageWidth", "imageHeight", "className", "hidden", "disabled", "style", "userdataName", "userdataValue"],
        multiselect: ["name", "type", "position", "offsetLeft", "offsetTop", "info", "label", "labelAlign", "labelWidth", "labelHeight", "labelLeft", "labelTop", "tooltip", "inputWidth", "inputHeight", "inputLeft", "inputTop", "value", "connector", "options", "style", "className", "hidden", "disabled", "required", "noteText", "noteWidth", "userdataName", "userdataValue"],
        column: ["columnOffset"],
        password: ["name", "type", "position", "offsetLeft", "offsetTop", "label", "labelAlign", "labelWidth", "labelHeight", "labelLeft", "labelTop", "info", "tooltip", "inputWidth", "inputHeight", "inputLeft", "inputTop", "maxLength", "value", "style", "className", "readonly", "disabled", "hidden", "validate", "required", "noteText", "noteWidth", "userdataName", "userdataValue"],
        radio: ["name", "type", "position", "offsetLeft", "offsetTop", "group", "label", "labelAlign", "labelWidth", "labelHeight", "labelLeft", "labelTop", "info", "tooltip", "inputLeft", "inputTop", "value", "checked", "className", "readonly", "disabled", "hidden", "required", "noteText", "noteWidth", "userdataName", "userdataValue"],
        settings: ["position", "offsetLeft", "offsetTop", "labelAlign", "labelWidth", "labelHeight", "inputWidth", "inputHeight", "blockOffset", "noteWidth"],
        template: ["name", "type", "position", "offsetLeft", "offsetTop", "label", "labelAlign", "labelWidth", "labelHeight", "labelLeft", "labelTop", "info", "tooltip", "inputWidth", "inputHeight", "inputLeft", "inputTop", "value", "formatFunc", "style", "className", "hidden", "required", "noteText", "noteWidth", "userdataName", "userdataValue"],
        uploader: ["name", "inputWidth", "inputHeight", "uploaderMode", "titleScreen", "titleText", "autoStart", "autoRemove", "uploaderUrl", "swfPath", "swfUrl", "slXap", "slUrl", "className", "hidden", "disabled", "userdataName", "userdataValue"]
    }, app.config.propGroups = {
        Common: {
            // test: {
            //     name: "test",
            //     type: "input",
            //     v: "str"
            // },
            name: {
                name: "name",
                type: "input",
                v: "str"
            },
            type: {
                name: "type",
                type: "select",
                v: "select",
                options: ["input", "password", "select", "multiselect", "combo", "checkbox", "radio", "label", "button", "hidden", "template", "container", "calendar", "colorpicker", "editor", "btn2state", "image", "uploader", "file"]
            }
            // blockType: {
            //     name: "type",
            //     type: "select",
            //     v: "select",
            //     options: ["block", "fieldset"]
            // },
            // position: {
            //     name: "position",
            //     type: "select",
            //     v: "select",
            //     options: ["", "label-left", "label-right", "label-top", "absolute"]
            // },
            // offsetLeft: {
            //     name: "offsetLeft",
            //     type: "input",
            //     v: "int"
            // },
            // offsetTop: {
            //     name: "offsetTop",
            //     type: "input",
            //     v: "int"
            // },
            // columnOffset: {
            //     name: "offset",
            //     type: "input",
            //     v: "int"
            // },
            // group: {
            //     name: "group",
            //     type: "input",
            //     v: "str"
            // }
        }
        // "Block Conf": {
        //     blockWidth: {
        //         name: "width",
        //         type: "input",
        //         v: "int"
        //     },
        //     blockOffset: {
        //         name: "blockOffset",
        //         type: "input",
        //         v: "int"
        //     }
        // },
        // "Fieldset Conf": {
        //     fieldsetWidth: {
        //         name: "width",
        //         type: "input",
        //         v: "int"
        //     }
        // },
        // "Label Conf": {
        //     label: {
        //         name: "label",
        //         type: "input",
        //         v: "str"
        //     },
        //     labelAlign: {
        //         name: "labelAlign",
        //         type: "select",
        //         v: "select",
        //         def: "left",
        //         options: ["", "left", "right", "center"]
        //     },
        //     labelWidth: {
        //         name: "labelWidth",
        //         type: "input",
        //         v: "int",
        //         auto: !0,
        //         def: "auto"
        //     },
        //     labelHeight: {
        //         name: "labelHeight",
        //         type: "input",
        //         v: "int",
        //         auto: !0,
        //         def: "auto"
        //     },
        //     labelLeft: {
        //         name: "labelLeft",
        //         type: "input",
        //         v: "int"
        //     },
        //     labelTop: {
        //         name: "labelTop",
        //         type: "input",
        //         v: "int"
        //     },
        //     info: {
        //         name: "info",
        //         type: "checkbox",
        //         v: "bool",
        //         def: !1
        //     },
        //     tooltip: {
        //         name: "tooltip",
        //         type: "input",
        //         v: "str"
        //     }
        // },
        // "Input Conf": {
        //     inputWidth: {
        //         name: "inputWidth",
        //         type: "input",
        //         v: "int",
        //         auto: !0,
        //         def: "auto"
        //     },
        //     inputHeight: {
        //         name: "inputHeight",
        //         type: "input",
        //         v: "int",
        //         auto: !0,
        //         def: "auto"
        //     },
        //     inputLeft: {
        //         name: "inputLeft",
        //         type: "input",
        //         v: "int"
        //     },
        //     inputTop: {
        //         name: "inputTop",
        //         type: "input",
        //         v: "int"
        //     },
        //     rows: {
        //         name: "rows",
        //         type: "input",
        //         v: "int"
        //     },
        //     maxLength: {
        //         name: "maxLength",
        //         type: "input",
        //         v: "int"
        //     },
        //     numberFormat: {
        //         name: "numberFormat",
        //         type: "input",
        //         v: "str"
        //     },
        //     value: {
        //         name: "value",
        //         type: "input",
        //         v: "str"
        //     },
        //     checked: {
        //         name: "checked",
        //         type: "checkbox",
        //         v: "bool"
        //     },
        //     connector: {
        //         name: "connector",
        //         type: "input",
        //         v: "str"
        //     },
        //     options: {
        //         name: "options",
        //         type: "input",
        //         v: "array"
        //     },
        //     buttonLabel: {
        //         name: "value",
        //         type: "input",
        //         v: "string"
        //     },
        //     buttonWidth: {
        //         name: "width",
        //         type: "input",
        //         v: "int",
        //         auto: !0,
        //         def: "auto"
        //     },
        //     formatFunc: {
        //         name: "format",
        //         type: "input",
        //         v: "str"
        //     }
        // },
        // "Combo Conf": {
        //     comboType: {
        //         name: "comboType",
        //         type: "select",
        //         v: "select",
        //         options: ["option", "checkbox", "image"]
        //     },
        //     comboImagePath: {
        //         name: "comboImagePath",
        //         type: "input",
        //         v: "str"
        //     },
        //     comboDefaultImage: {
        //         name: "comboDefaultImage",
        //         type: "input",
        //         v: "str"
        //     },
        //     comboDefaultImageDis: {
        //         name: "comboDefaultImageDis",
        //         type: "input",
        //         v: "str"
        //     },
        //     filtering: {
        //         name: "filtering",
        //         type: "checkbox",
        //         v: "bool",
        //         def: !1
        //     },
        //     serverFiltering: {
        //         name: "serverFiltering",
        //         type: "input",
        //         v: "str"
        //     },
        //     filterCache: {
        //         name: "filterCache",
        //         type: "checkbox",
        //         v: "bool",
        //         def: !1
        //     },
        //     filterSubLoad: {
        //         name: "filterSubLoad",
        //         type: "checkbox",
        //         v: "bool",
        //         def: !1
        //     }
        // },
        // "Calendar Conf": {
        //     dateFormat: {
        //         name: "dateFormat",
        //         type: "input",
        //         v: "str"
        //     },
        //     serverDateFormat: {
        //         name: "serverDateFormat",
        //         type: "input",
        //         v: "str"
        //     },
        //     calendarPosition: {
        //         name: "calendarPosition",
        //         type: "select",
        //         v: "select",
        //         options: ["bottom", "right"]
        //     },
        //     minutesInterval: {
        //         name: "minutesInterval",
        //         type: "select",
        //         v: "select",
        //         options: ["1", "5", "10", "15"]
        //     },
        //     weekStart: {
        //         name: "weekStart",
        //         type: "select",
        //         v: "select",
        //         options: ["1", "2", "3", "4", "5", "6", "7"]
        //     },
        //     enableTime: {
        //         name: "checked",
        //         type: "checkbox",
        //         v: "bool"
        //     },
        //     showWeekNumbers: {
        //         name: "checked",
        //         type: "checkbox",
        //         v: "bool",
        //         def: !0
        //     }
        // },
        // "ColorPicker Conf": {
        //     cpPosition: {
        //         name: "cpPosition",
        //         type: "select",
        //         v: "select",
        //         options: ["bottom", "right"]
        //     },
        //     enableCustomColors: {
        //         name: "enableCustomColors",
        //         type: "checkbox",
        //         v: "bool",
        //         def: !1
        //     },
        //     customColors: {
        //         name: "customColors",
        //         type: "input",
        //         v: "str"
        //     }
        // },
        // "Uploader Conf": {
        //     uploaderMode: {
        //         name: "mode",
        //         type: "select",
        //         v: "select",
        //         options: ["auto", "html5", "html4", "flash", "sl"]
        //     },
        //     titleScreen: {
        //         name: "titleScreen",
        //         type: "checkbox",
        //         v: "bool",
        //         def: !0
        //     },
        //     titleText: {
        //         name: "titleText",
        //         type: "input",
        //         v: "str",
        //         def: "Drag-n-Drop files here or click to select files for upload"
        //     },
        //     autoStart: {
        //         name: "autoStart",
        //         type: "checkbox",
        //         v: "bool",
        //         def: !1
        //     },
        //     autoRemove: {
        //         name: "autoRemove",
        //         type: "checkbox",
        //         v: "bool",
        //         def: !1
        //     },
        //     uploaderUrl: {
        //         name: "url",
        //         type: "input",
        //         v: "str"
        //     },
        //     swfPath: {
        //         name: "swfPath",
        //         type: "input",
        //         v: "str"
        //     },
        //     swfUrl: {
        //         name: "swfUrl",
        //         type: "input",
        //         v: "str"
        //     },
        //     slXap: {
        //         name: "slXap",
        //         type: "input",
        //         v: "str"
        //     },
        //     slUrl: {
        //         name: "slUrl",
        //         type: "input",
        //         v: "str"
        //     }
        // },
        // "Image Conf": {
        //     imageUrl: {
        //         name: "url",
        //         type: "input",
        //         v: "str"
        //     },
        //     imageWidth: {
        //         name: "imageWidth",
        //         type: "input",
        //         v: "int"
        //     },
        //     imageHeight: {
        //         name: "imageHeight",
        //         type: "input",
        //         v: "int"
        //     }
        // },
        // Misc: {
        //     style: {
        //         name: "style",
        //         type: "input",
        //         v: "str"
        //     },
        //     className: {
        //         name: "className",
        //         type: "input",
        //         v: "str"
        //     },
        //     cssName: {
        //         name: "cssName",
        //         type: "input",
        //         v: "str"
        //     },
        //     disabled: {
        //         name: "disabled",
        //         type: "checkbox",
        //         v: "bool"
        //     },
        //     hidden: {
        //         name: "hidden",
        //         type: "checkbox",
        //         v: "bool"
        //     },
        //     readonly: {
        //         name: "readonly",
        //         type: "checkbox",
        //         v: "bool"
        //     },
        //     validate: {
        //         name: "validate",
        //         type: "select",
        //         v: "select",
        //         options: ["", "isEmpty", "isNotEmpty", "isValidBoolean", "isValidEmail", "isValidInteger", "isValidNumeric", "isValidAplhaNumeric", "isValidDatetime", "isValidDate", "isValidTime", "isValidIPv4", "isValidCurrency", "isValidSSN", "isValidSIN"]
        //     },
        //     required: {
        //         name: "required",
        //         type: "checkbox",
        //         v: "bool"
        //     }
        // },
        // Note: {
        //     noteText: {
        //         name: "noteText",
        //         type: "input",
        //         v: "str",
        //         def: ""
        //     },
        //     noteWidth: {
        //         name: "noteWidth",
        //         type: "input",
        //         v: "int",
        //         auto: !0,
        //         def: "auto"
        //     }
        // },
        // UserData: {
        //     userdataName: {
        //         name: "userdataName",
        //         type: "input",
        //         v: "str"
        //     },
        //     userdataValue: {
        //         name: "userdataValue",
        //         type: "input",
        //         v: "str"
        //     }
        // }
    }
};