// setting up layout settings
// define your custom settings here
const containerMarginSettings = {
  default: {
    // custom offset for body
    dhtmlXCellTop: {
      base: { t: 0, b: 0, l: 0, r: 0 }, // 5598
      attachRibbon: { t: 0, b: 0, l: 0, r: 0 }, // 5895
      attachToolbar: { t: 0, b: 0, l: 0, r: 0 } // 5837
    },
    // dhtmlXPopup
    dhtmlXPopup: { skinParams: { t0: 19, t1: 9, t2: 19, t3: 9 } }, // 13598
    dhtmlXCellObject: {
      attachTabbar: { dhtmlXTabBarCell: { t: 8, r: 8, b: 8, l: 8 } }, // 70529
      attachLayout: {
        dhtmlXTabBarCell: { t: 8, r: 8, b: 8, l: 8 }, // 69246
        dhtmlXAccordionCell: { t: 0, r: 0, b: 0, l: 0 }, // 69238
        dhtmlXWindowsCell: { t: 3, r: 3, b: 3, l: 3 } // 69268
      }
    },
    dhtmlXAccordion: { m: { between: 12, left: 0, right: 0 } }, // 65940
    offsetLabels: true
  },
  akioma: {
    // custom offset for body
    dhtmlXCellTop: {
      base: { t: 0, b: 0, l: 0, r: 0 }, // 5598
      attachRibbon: { t: 0, b: 0, l: 0, r: 0 }, // 5895
      attachToolbar: { t: 0, b: 0, l: 0, r: 0 } // 5837
    },
    // dhtmlXPopup
    dhtmlXPopup: { skinParams: { t0: 19, t1: 9, t2: 19, t3: 9 } }, // 13598
    dhtmlXCellObject: {
      attachTabbar: { dhtmlXTabBarCell: { t: 8, r: 8, b: 8, l: 8 } }, // 70529
      attachLayout: {
        dhtmlXTabBarCell: { t: 8, r: 8, b: 8, l: 8 }, // 69246
        dhtmlXAccordionCell: { t: 0, r: 0, b: 0, l: 0 }, // 69238
        dhtmlXWindowsCell: { t: 3, r: 3, b: 3, l: 3 } // 69268
      }
    },
    dhtmlXAccordion: { m: { between: 12, left: 0, right: 0 } }, // 65940
    offsetLabels: true
  },
  osiv: {

    // custom offset for body
    dhtmlXCellTop: {
      base: { t: 0, b: 0, l: 0, r: 0 }, // 5598
      attachRibbon: { t: 0, b: 0, l: 0, r: 0 }, // 5895
      attachToolbar: { t: 0, b: 0, l: 0, r: 0 } // 5837
    },
    // dhtmlXPopup
    dhtmlXPopup: { skinParams: { t0: 19, t1: 9, t2: 19, t3: 9 } }, // 13598
    dhtmlXCellObject: {
      attachTabbar: { dhtmlXTabBarCell: { t: 0, r: 0, b: 0, l: 0 } }, // 70529
      attachLayout: {
        dhtmlXTabBarCell: { t: 0, r: 0, b: 0, l: 0 }, // 69246
        dhtmlXAccordionCell: { t: 0, r: 0, b: 0, l: 0 }, // 69238
        dhtmlXWindowsCell: { t: 3, r: 3, b: 3, l: 3 } // 69268
      }
    },
    dhtmlXAccordion: { m: { between: 0, left: 0, right: 0 } }, // 65940
    offsetLabels: true
  },
  zero: {
    // custom offset for body
    dhtmlXCellTop: {
      base: { t: 0, b: 0, l: 0, r: 0 }, // 5598
      attachRibbon: { t: 0, b: 0, l: 0, r: 0 }, // 5895
      attachToolbar: { t: 0, b: 0, l: 0, r: 0 } // 5837
    },
    // dhtmlXPopup
    dhtmlXPopup: { skinParams: { t0: 0, t1: 0, t2: 0, t3: 0 } }, // 13598
    dhtmlXCellObject: {
      attachTabbar: { dhtmlXTabBarCell: { t: 0, r: 0, b: 0, l: 0 } }, // 70529
      attachLayout: {
        dhtmlXTabBarCell: { t: 0, r: 0, b: 0, l: 0 }, // 69246
        dhtmlXAccordionCell: { t: 0, r: 0, b: 0, l: 0 }, // 69238
        dhtmlXWindowsCell: { t: 0, r: 0, b: 0, l: 0 } // 69268
      }
    },
    dhtmlXAccordion: { m: { between: 0, left: 0, right: 0 } }, // 65940
    offsetLabels: false
  }
};

// global context function
// eslint-disable-next-line no-unused-vars
const AkDhxLayoutOffsetsManager = {
  _selected: 'default',

  setLayoutSettings: function(cType) {
    this._selected = cType;
  },

  getLayoutSettings: function() {
    return containerMarginSettings[this._selected];
  }
};


// change your settings here
// AkDhxLayoutOffsetsManager.setLayoutSettings('osiv');

// note: tabbar cell still adds padding from css

