var cGlobalDropID = null;

dhtmlXMockup.prototype.initCustomDrop = function (grid) {
    var mockup = this,
        mockupRef = mockup.conf.parent.getElementsByClassName('dhxmockup_el_base'),
        dragRowId;

    function onDragGrid(id, colId, e) {
        if(mockupRef){
            $(document).on('mousemove', '.dhxmockup_el_base', mockup._mockupMouseMove);
            $(document).on('mousemove', '.dhxmockup_el_base', mockup._onDragScroll);
            $(document).on('mouseup', '.dhxmockup_el_base', mockup._dropToMockup);
            document.addEventListener('mouseup', mockup._mouseUpDocument);
            dragRowId = id;
            mockup.dnd = Object.create(mockup.dndMap['item']);
        }
        
        return true;
    }
    if(cGlobalDropID)
        grid.detachEvent(cGlobalDropID);
    cGlobalDropID = grid.attachEvent('onBeforeDrag', onDragGrid);


    this._mockupMouseMove = function (e) {
        if (dragRowId === undefined) {
            return;
        }
        var mockupRef = $(e.target).closest('.dhxmockup_material').find('> .dhxmockup_el_base')[0];
        var dnd = mockup.dnd,
            mockupPos = mockupRef.getBoundingClientRect();
        if (!dnd.el) {
            if (Math.abs(mockupPos.left + mockupRef.offsetWidth - e.pageX) < 5 ||
                Math.abs(mockupPos.top + mockupRef.offsetHeight - e.pageY) < 5) {
                return;
            }
            dnd.mockup = mockup;
            dnd.cache = {};
            dnd.el = document.createElement('div');
            dnd.el.style.width = '188px';
            dnd.el.style.height = '32px';
            dnd.el.className = 'dhxmockup_dnd_item_clone';

            // load combo/label/select/input
            var cType =grid.akElm.dataSource.dhx.item(dragRowId).objecttypename.toLowerCase();
            var bCombo = cType.indexOf('combo') > -1;
            var bInput = cType.indexOf('text') > -1;
            var bSelect  = cType.indexOf('lookup') > -1;
            if(bCombo)
                dnd.el.innerHTML = 'combo';
            else if(bInput)
                dnd.el.innerHTML = 'input';
            else if(bSelect)
                dnd.el.innerHTML = 'select';
            else{
                
                dnd.el.innerHTML = 'input';
            }
            dnd.el.style.position = 'absolute';
            dnd.el.style.zIndex = 10000;
            mockupRef.appendChild(dnd.el);
        }
        dnd.el.style.left = (e.pageX - mockupPos.left - (188 / 2)) + 'px';
        dnd.el.style.top = (e.pageY - mockupPos.top - 10) + 'px';
        dnd.showCapture(e);
    };

    this._dropToMockup = function(e) {
        e.stopPropagation();
        if (dragRowId != undefined) {
            var catcherElem = mockup.dnd.getCatcher(e);
            if (!catcherElem) {
                return;
            }
            var childIndex = mockup.dnd.getChildIndex(e, catcherElem._idd);
            mockup._hideCapture();
            var referenceElement = mockup._nthChild(catcherElem, childIndex + 1);

            const oDesignerPropertyGrid = grid.akElm.dynObject.container.getFirstChildByType('propertygrid');
            const activeFormBuilder = grid.akElm.dynObject.container.getFirstChildByType('formbuilder');

            // configure new item for mockup
            var itemId = window.dhx4.newId().toString(),
                item = document.createElement("DIV");
            item._idd = itemId;
            item._type = "item";
            item.className = "dhxmockup_el_item";
            item.innerHTML = (mockup._icons ? "<span class='icon'></span>" : "<span></span>") + "<span></span>" +
                "<div class='dhxmockup_btn_del dhxmockup_btn_del_item' title='Delete Item'>x</div>";

            if(mockup._baseID == catcherElem._idd)
                catcherElem.children[0].children[0].insertBefore(item, catcherElem.children[0].children[0].children[childIndex]);
            else
                catcherElem.insertBefore(item, referenceElement);


            var oDraggedItem = grid.akElm.dataSource.dhx.item(dragRowId);
            var cType = oDraggedItem.objecttypename.toLowerCase();

            var oElm = {
                objname: oDraggedItem.objectname,
                objtype: oDraggedItem.objecttypename
            };
            var oField = activeFormBuilder.getNewFormField(oElm);

            var cFormType = oField.type;

            // now add new form field in formbuilder 
            var oDraggedObj = grid.akElm.dataSource.dhx.item(dragRowId);
            var oInstanceStore = activeFormBuilder.businessEntity.getStore('eSmartObjectInstance');
            activeFormBuilder.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');


            // need to fix this sequence
            var iSequence = 1;


            // get parentinstanceguid if any
            var _getParentWithGuid = function(mockup,id){
            	if(mockup.items[id].formData != undefined && mockup.items[id].formData.type == 'root')
                    return { formData: { guid: "" }};
                if((mockup.items[id].formData == undefined || mockup.items[id].formData.guid == undefined) || 
                    mockup.items[id].formData.type == 'column'){
                    if(mockup.items[id].parentId)
                        return _getParentWithGuid(mockup, mockup.items[id].parentId);
                    else
                        return { formData: { guid: "" }};
                }else
                    return mockup.items[id];
            }
            var oParent = _getParentWithGuid(mockup, catcherElem._idd);

            // add new dropped instance on the element
            try{
                var cNewFieldName = oDraggedObj.objectname;

                if(cNewFieldName.indexOf('.') > -1)
                    cNewFieldName = cNewFieldName.substr(cNewFieldName.indexOf('.') + 1);

                var aStoreElms = Object.values(oInstanceStore.data.pull);
                var aTempStore = activeFormBuilder.aTempContainerFields;
                var aAllStore = aStoreElms.concat(aTempStore);
                var cUniqueInstanceName = akioma.SearchUniqueInstanceName(cNewFieldName, Object.values(aAllStore));

            }catch(e){
                console.log(e);
            }

            if(cUniqueInstanceName == undefined)
                cUniqueInstanceName = oDraggedObj.objectname + ' ' + dhtmlx.uid();

            var cNewObjInsGuid = uuid();

            var cNextAddedInsID = cNewObjInsGuid;

            oInstanceStore.add({
                instancedescription: '',
                instancename: cUniqueInstanceName,
                objectinstanceguid: cNewObjInsGuid,
                containerobjectmasterguid: activeFormBuilder.cLoadedObjMasterGuid,
                objectmasterguid: oDraggedObj.objectmasterguid,
                objectmastername: oDraggedObj.objectname,
                objectsequence: (iSequence + 1),
                parentinstanceguid: oParent.formData.guid
            },cNextAddedInsID);

            // clear mock last selection
            akioma.RepositoryStructure.fetchMasterDesignAttributes(oDraggedObj.objectmasterguid).done(function(oResult){

                activeFormBuilder.aListNewInstances.push(cUniqueInstanceName);
                activeFormBuilder.aListNewTempGuids.push(cNextAddedInsID);

                
                var aProps = oResult.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue;
                aProps = _.sortBy( aProps, function( item ) { 
                    item.ObjectInstanceGuid = cNextAddedInsID;
                    return [item.ObjectInstanceGuid, item.AttributeLabel].join("_").toLowerCase();
                } );
                var iDif = 0;

                

                aProps.unshift({
                    AttributeLabel: "ObjectMasterName",
                    name: "objectmastername",
                    type: "input",
                    RepositoryType: 'Character',
                    CharacterValue: oDraggedObj.objectname,
                    ObjectInstanceGuid: cNextAddedInsID,
                    ObjectMasterGuid: oDraggedObj.objectmasterguid,
                    ObjectType: oDraggedObj.objecttypename,
                    openExternal: true,
                    readonly: true,
                    openExternal: true,
                    readonly: true,
                    IsInherited: false,
                    TechnicalName: "ObjectMasterName",
                    PropertyOrEvent: true,
                    AppliesAtRuntime: true,
                    ConstantValue: false
                });

                aProps.unshift({
                    AttributeLabel: "name",
                    name: "name",
                    type: "input",
                    CharacterValue: oDraggedObj.objectname.split('.')[1],
                    RepositoryType: 'Character',
                    ObjectMasterGuid: oDraggedObj.objectmasterguid,
                    IsInherited: false,
                    TechnicalName: "name",
                    PropertyOrEvent: true,
                    AppliesAtRuntime: true,
                    ConstantValue: false
                });

                var aNewAttrs = {};
                for(var a in aProps){
                    aProps[a].ObjectInstanceGuid = cNewObjInsGuid;
                    aProps[a].AttributeValueGuid = null;
                    if(activeFormBuilder.props[cNextAddedInsID] == undefined){
                        activeFormBuilder.props[cNextAddedInsID] = [];
                        iDif = parseInt(a);
                    }
                    var logical = false;
                    if(aProps[a].RepositoryType.toUpperCase() == 'LOGICAL')
                        logical = true;

                    var cType = 'input';

                    if(logical)
                        cType = 'checkbox';
                    var cVal = (logical ? aProps[a].LogicalValue : aProps[a].CharacterValue);

                    if(aProps[a].RepositoryType.toUpperCase() == 'DECIMAL'){
                        if(aProps[a].DecimalValue != null)
                            cVal = parseFloat(aProps[a].DecimalValue);
                        else
                            cVal = 0;
                    }else if(aProps[a].RepositoryType.toUpperCase() == 'INTEGER'){
                        if(aProps[a].IntegerValue != null)
                            cVal = parseInt(aProps[a].IntegerValue);
                        else
                            cVal = 0;
                    }

                    activeFormBuilder.props[cNextAddedInsID].push({
                        label: aProps[a].AttributeLabel,
                        type: cType,
                        _idd: parseInt(a)-iDif+'_'+ cNextAddedInsID,
                        value: cVal,
                        original: aProps[a],
                        ...aProps[a]
                    });
                    aNewAttrs[aProps[a].AttributeLabel] = (logical ? aProps[a].LogicalValue : aProps[a].CharacterValue);
                }
                
                aNewAttrs.type = cFormType;

                activeFormBuilder.cNextAddedInsID = null;

                aNewAttrs.guid = cNextAddedInsID;

                mockup.items[itemId] = {
                    parentId: catcherElem._idd,
                    type: "item",
                    node: item,
                    formData: aNewAttrs
                };
                mockup.items[itemId].formData.name = cUniqueInstanceName;
                mockup.items[itemId].formData.label = mockup.items[itemId].formData.name;

                mockup.items[itemId].formData.parentinsguid = oParent.formData.guid;
                item = null;

                mockup._makeItemName(itemId);
                if (mockup._icons) {
                    mockup._makeItemIcon(itemId)
                }

                // must switch back to instances mode after adding an instance
                akioma.repository.setGenericDesignerPropsContext({ 
                    typeOfRecord: 'instances', 
                    dataSource: oDesignerPropertyGrid.dataSource, 
                    propertyGrid: oDesignerPropertyGrid
                });

                // create new item element in mockup
                mockup.callEvent("onAdd", [catcherElem._idd, itemId, mockup.items[itemId]]);

                // save new sequences
                activeFormBuilder.applySequenceMove(mockup.items, _getParentWithGuid(mockup, catcherElem._idd), mockup.dndMap.item, catcherElem._idd, itemId);
            });

            var oInstanceJSDO = activeFormBuilder.businessEntity.jsdo[activeFormBuilder.businessEntity.entityName];
            oInstanceJSDO._jsdo._buffers[oInstanceJSDO._parent]._setRecord(oInstanceJSDO._jsdo._buffers[oInstanceJSDO._parent]._findFirst());
        }
    };

    this._mouseUpDocument = function () {

        $(document).off('mousemove', '.dhxmockup_el_base', mockup._mockupMouseMove);
        $(document).off('mousemove', '.dhxmockup_el_base', mockup._onDragScroll);
        $(document).off('mouseup', '.dhxmockup_el_base', mockup._dropToMockup);

        document.removeEventListener('mouseup', mockup._mouseUpDocument);
        mockup._hideCapture();
        if (mockup.dnd.el) {
            mockup.dnd.el.remove();
        }
        dragRowId = undefined;
    };


    this._destroy = function(){
        // todo
    }
};
