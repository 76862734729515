EQ.core.texts = {
	German: {
		Locale: "de",
	    AltMenuAttribute: "Attribute",
	    AltMenuConstantExpression: "Constant expression",
	    ButtonApply: "Ausführen",
	    ButtonCancel: "Abbrechen",
	    ButtonOK: "OK",
	    ButtonEnable: "Aktiv/Inaktiv",
	    ButtonDelete: "Löschen",
	    ButtonAddCondition: "Bedingung hinzufügen",
	    ButtonAddPredicate: "Weiteren Filter hinzufügen",
	    CmdAddConditionAfter: "Bedingung nach Zeile hinzufügen",
	    CmdAddConditionInto: "Weitere Bedingung hinzufügen",
	    CmdAddPredicateAfter: "Open a bracket after the current row",
	    CmdAddPredicateInto: "Open a bracket",
	    CmdClickToAddCondition: "[Bedingung hinzufügen]",
	    CmdDeleteRow: "Zeile löschen",
	    ErrIncorrectPredicateTitleFormat: "Ungültiges Titel-Format",
	    ErrNotNumber: " ist keine Zahl",
	    ErrIncorrectInteger: "Ungültige Ganzzahl",
	    ErrIncorrectNumberList: "Ungültiges Listformat",
	    False: "Unwahr",
	    FirstDayOfMonth: "Erster Tag im Monat",
	    FirstDayOfYear: "Erster Tag im Jahr",
	    HourStart: "Start der Stunde",
	    LinkTypeAll: "Alle",
	    LinkTypeAny: "Eine",
	    LinkTypeNone: "Keine",
	    LinkTypeNotAll: "Nicht alle",
	    ConjAll: "und",
	    ConjAny: "oder",
	    ConjNotAll: "",
	    ConjNone: "",
	    Midnight: "Mitternacht",
	    MsgApplySelection: "[Auswahl ausführen]",
	    MsgAs: "als",
	    MsgEmptyList: "(Leere Liste)",
	    MsgEmptyListValue: "[Eintrag wählen]",
	    MsgEmptyScalarValue: "[Wert eingeben]",
	    MsgSubQueryValue: "[edit sub-query]",
	    MsgOf: "von",
	    Noon: "Noon",
	    Now: "Jetzt",
	    PredicateTitle: "{lt} der folgenden Bedingungen",
	    RootPredicateTitle: "Finde Datensätze die {lt} der folgenden Bedingungen erfüllen",
	    StrAddConditions: "Bedingung hinzufügen",
	    SubQueryDialogTitle: "Edit sub-query",
	    SubQueryColumnTitle: "Feld:",
	    SubQueryEmptyColumn: "[Feld auswählen]",
	    SubQueryQueryPanelCaption: "Bedingungen",
	    Today: "Heute",
	    Tomorrow: "Morgen",
	    True: "Wahr",
	    Yesterday: "Gestern",

	    ButtonSorting: "Sortieren",
	    ButtonToAggr: "Change to aggregate column",
	    ButtonToSimple: "Change to simple column",
	    CmdAscending: "Aufsteigend",
	    CmdClickToAddColumn: "[Feld hinzufügen]",
	    CmdDeleteColumn: "Feld löschen",
	    CmdDeleteSorting: "Sortierung entfernen",
	    CmdDescending: "Absteigend",
	    CmdGroupSort: "Sortierung",
	    CmdNotSorted: "Unsortiert",
	    ColTypeAggrFunc: "Aggregate function",
	    ColTypeCompound: "Calculated",
	    ColTypeGroup: "Feldtyp",
	    ColTypeSimple: "Simple column",
	    HeaderExpression: "Expression",
	    HeaderSorting: "Sorting",
	    HeaderTitle: "Title",
	    SortHeaderColumn: "Column",
	    SortHeaderSorting: "Sorting",
	    StrAddColumns: "Add columns",

	    CmdMoveToStart: "Zum Anfang",
	    CmdMoveRight: "Rechts",
	    CmdMoveLeft: "Links",
	    CmdMoveToEnd: "Zum Ende",
	    ButtonMenu: "Menü anzeigen",
	    CmdToSimple: "Not aggregated"
	},

	Romanian: {
		Locale: "ro",
		ButtonAddCondition: "Adauga conditie",
	    ButtonAddPredicate: "Adauga un grup de conditii",
	    CmdAddConditionInto: "Adauga conditie noua",
	    CmdClickToAddCondition: "[Adauga conditie noua]",
        LinkTypeAll: "toate",
	    LinkTypeAny: "oricare",
	    LinkTypeNone: "niciuna",
	    LinkTypeNotAll: "nu toate",
	    ConjAll: "si",
	    ConjAny: "sau",
	    Today: "Astazi",
		RootPredicateTitle: "Selecteaza inregistrarile unde {lt} se aplica"
	},

	Spanish: {
		Locale: "es",
		ButtonAddCondition: "Agregar condicion",
		CmdAddConditionInto: "Agregar nueva condicion",
	    CmdClickToAddCondition: "[Agregar nueva condicion]",
	    LinkTypeAll: "todos",
	    LinkTypeAny: "cualquier",
	    LinkTypeNone: "ninguno",
	    LinkTypeNotAll: "no todos",
	    ConjAll: "y",
	    ConjAny: "o",
	    Today: "Hoy",
		RootPredicateTitle: "Selecciona los registros donde {lt} aplicar"
	}
};