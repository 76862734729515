/**

 * IndexedDbStore is an indexedDb cache store for data.
 * @class  IndexedDbStore
 * @param {string} db_name   The database name to use.
 * @param {string} db_table  The table name
 * @param {string} db_schema The indexedDb schema for the db_table
 */
var IndexedDbStore = function(db_name, db_table, db_schema ){
  this.debug = false;

  this.db_name = db_name;
  this.db_table = db_table;
  this.db_schema = db_schema;

  this._init();

};


/**
 * Initializes the indexed db store
 * @instance
 * @private
 * @memberOf IndexedDbStore
 */
IndexedDbStore.prototype._init = function(){
  this.db = new Dexie(this.db_name); //new db


  var conf = {};
  conf[this.db_table] = this.db_schema; // setup db table with schema

  this.db.version(1).stores(conf);

};

/**
 * Add data to cache on the provided name key.
 * @param {string} name      The name to use for saving the cache entry.
 * @param {Timestamp} timestamp Date.now() timestamp for saving the timestamp
 * @param {Array} aSettings The settings/data to save in local store
 * @instance
 * @memberOf IndexedDbStore
 */
IndexedDbStore.prototype.addToCache = function(name, timestamp, aSettings) {

  const tenant = akioma.sessionContext.eSwatSessionContext.tenantName || '';
  const keyVal = `${tenant}@${name}`;

  this.db[this.db_table].put({
    name: keyVal,
    timestamp: timestamp,
    data: aSettings
  }).then((res) => {
    if (this.debug)
      akioma.notification({ type: 'info', text: 'Successfully added new entry to IndexedDbStore for "' + name + '".', expire: 3000 });
  }).catch(function(res){
    akioma.notification({ type: 'error', text: 'Error adding new entry to IndexedDbStore for "' + name + '".' });
  });
};

/**
 * Method used for returning cache entries from indexedDb local store.
 * @param  {string} name      The name on which we saved the cache entry.
 * @memberOf IndexedDbStore
 * @return {Promise}
 */
IndexedDbStore.prototype.getFromCache = function(name) {
  var deferred = $.Deferred();

  const tenant = akioma.sessionContext.eSwatSessionContext.tenantName || '';
  const keyVal = `${tenant}@${name}`;

  this.db[this.db_table].where('name').equals(keyVal).toArray((res) => {
    if (this.debug)
    akioma.notification({ type: 'info', text: 'Successfully returned new entry to IndexedDbStore for "' + name + '".' , expire: 3000});

    if (res[0])
      deferred.resolve(res[0]);
    else
      deferred.resolve(res);

  }).catch((error) => {
    akioma.notification({ type: 'error', text: 'Error reading entry from IndexedDbStore for "' + name + '".' });
    deferred.reject();
  });


  return deferred.promise();
};

/**
 * Method used for deleting entries from IndexedDbCache Store.
 * @param  {string} name      The name of the cache entry to delete
 * @memberOf IndexedDbStore
 * @return {Promise}
 */
IndexedDbStore.prototype.deleteFromCache = function(name) {
  var deferred = $.Deferred(),
    oSelf = this;
  oSelf.db[this.db_table].delete(name).then(function(res){
    if (oSelf.debug)
    akioma.notification({ type: 'info', text: 'Successfully deleted entry from IndexedDbStore for "' + name + '".', expire: 3000 });
  }).catch(function(res){
    akioma.notification({ type: 'error', text: 'Error deleting entry from IndexedDbStore for "' + name + '".' });
    deferred.reject();
  });

  return deferred.promise();
};


// check if found record timestamp, to check for updated data
/**
 * Method used for checking the timestamp for new changes of the cached data.
 * @param {string} dynselectEntityNames The dynselectEntityNames to look for in the local database.
 * @memberOf IndexedDbStore
 * @return {Promise} The request promise
 */
IndexedDbStore.prototype.checkForNew = function(dynselectEntityNames) {
  return akioma.invokeServerTask(
    {
      name: 'Akioma.Swat.OERA.BusinessEntityBT',
      methodName: 'GetTimeLastChanged',
      paramObj: {
        plcParameter: {
          "Value": dynselectEntityNames
        }
      }
    }).fail(function(){
      akioma.notification({ type: 'error', text: 'Failed to call "' + name + '", methodName: "GetTimeLastChanged".', expire: 8000 });
  });
};

/**
 * akCacheDb is an indexedDbStore the cache store
 * @type {IndexedDbStore}
 */
var akCacheDb = new IndexedDbStore('cache','dynselects','name,timestamp,data');